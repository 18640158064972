import React from 'react';
import tw, {styled} from 'twin.macro';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Container} from '../Grid';
import {Text} from '../Typography';

const Wrapper = styled.section`
  ${tw``}
  background-color: #f3f8f9;
  padding-top: 167px;
  padding-bottom: 101px;
`;

const Content = styled.div`
  ${tw`flex flex-col md:flex-row justify-between`}
`;

const Heading = styled.h1`
  ${tw`text-secondary text-center`}
  margin-bottom: 22px;
  pointer-events: none;

  @media ${props => props.theme.screens.md} {
    ${tw`text-left`}
    flex-basis: 50%;
    font-size: 45px;
    line-height: 56px;
  }

  @media ${props => props.theme.screens.lg} {
    ont-size: 50px;
    line-height: 64px;
  }

  font-size: 30px;
  line-height: 42px;
`;
const StyledText = styled(Text)`
  ${tw`text-secondary text-center`}
  a {
    color: #039259;
    text-decoration: underline;
  }
  @media ${props => props.theme.screens.md} {
    ${tw`text-left flex-grow`}
    font-weight: 500;
    font-size: 19px;
    line-height: 32px;
    flex: 1;
    max-width: 504px;
    margin-left: 30px;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 21px;
    margin-left: 0px;
  }
`;

export const TextBanner: React.FC<{heading: string; text: string}> = ({
  heading,
  text,
}) => {
  const {t} = useTranslation();

  return (
    <Wrapper>
      <Container>
        <Content>
          <Heading dangerouslySetInnerHTML={{ __html: t(heading)}} />
          <StyledText dangerouslySetInnerHTML={{ __html: t(text)}} />
        </Content>
      </Container>
    </Wrapper>
  );
};
