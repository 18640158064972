import React from 'react';
import {graphql} from 'gatsby';
import tw, {styled, theme} from 'twin.macro';
import Layout from '../components/Layout';
import data from '../config/site_data.json';
import SEO from '../components/Seo';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {TextBanner} from '../components/Page/TextBanner';
import {Container, Section} from '../components/Grid';
import {Heading} from '../components/Typography';
import DynamicImage from '../components/DynamicImage';
import ContactFormPage from '../components/Page/ContactFormPage';
import {FaqFormWrapper} from './window-washing';

const InfoWrapper = styled(Section)`
  ${tw`relative`}

  @media ${props => props.theme.screens.xl} {
    margin-top: 180px;
    margin-bottom: 120px;
  }
`;

const Content = styled.div`
  @media ${props => props.theme.screens.sm} {
    max-width: 80%;
    margin: 0 auto;
  }
  @media ${props => props.theme.screens.md} {
    max-width: 50%;
    margin: 0;
  }

  @media ${props => props.theme.screens.xl} {
    max-width: 600px;
  }
`;

const StyledHeading = styled(Heading)`
  ${tw`text-secondary`}

  @media ${props => props.theme.screens.md} {
    font-size: 40px;
    line-height: 127%;
  }
`;

const Text = styled.p`
  ${tw`my-6  text-secondary`}
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;

  a {
    color: #039259;
    text-decoration: underline;
  }

  @media ${props => props.theme.screens.lg} {
    font-size: 19px;
    line-height: 31px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 226px;

  @media ${props => props.theme.screens.md} {
    position: absolute;
    width: 100%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 400px;
  }
  @media ${props => props.theme.screens.lg} {
    width: 50%;
    right: 10%;
  }

  @media ${props => props.theme.screens.xl} {
    left: calc(100% - ((100% - ${theme('screens').xl}) / 2));
    transform: translate(-100%, -50%);
    max-width: 500px;
  }
`;

const PostConstructionCleaning = () => {
  const {language, t} = useI18next();

  const {heading, text, info} = data.post_construction_cleaning;
  const {title, description_one, description_two, image} = info;
 
  return (
    <Layout headerStyle="windowWashing">
      <SEO
        title={t('post_construction_cleaning_seo_title')}
        description={t('post_construction_cleaning_seo_description')}
        keywords={t('post_construction_cleaning_seo_keywords', {
          returnObjects: true,
        })}
        lang={language}
      />
      <TextBanner heading={heading} text={text} />
      <InfoWrapper>
        <ImageContainer>
          <DynamicImage filename={image} />
        </ImageContainer>
        <Container>
          <Content>
            <StyledHeading>{t(title)}</StyledHeading>
            <Text dangerouslySetInnerHTML={{ __html: t(description_one)}} />
            <Text dangerouslySetInnerHTML={{ __html: t(description_two)}} />
          </Content>
        </Container>
      </InfoWrapper>
      <Container>
        <FaqFormWrapper>
          <ContactFormPage />
        </FaqFormWrapper>
      </Container>
    </Layout>
  );
};

export default PostConstructionCleaning;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: {eq: $language}
        ns: {in: ["common", "post_construction", "seo_post_construction"]}
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
